<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">屬性管理</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <button
          v-show="!grid.isEditing"
          class="button text-white bg-theme-1 shadow-md mr-2 flex items-center"
          @click="grid.addNewRow"
        >
          <FontAwesome icon="plus" type="fas" class="w-4 h-4 mr-1" />
          新增
        </button>
        <div
          v-show="!grid.isEditing && grid.isRowSelected"
          id="batch-dropdown"
          class="dropdown ml-auto mr-2 sm:ml-0"
        >
          <button
            class="dropdown-toggle button px-2 box bg-blue-500 text-white flex items-center"
          >
            <FontAwesome icon="tasks" type="fas" class="w-4 h-4 mr-1" />
            群組操作
          </button>
          <div class="dropdown-box">
            <div class="dropdown-box__content box dark:bg-dark-1 p-2 w-32">
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.removeSelectedRows"
              >
                <FontAwesome icon="trash" type="fas" class="w-4 h-4 mr-2" />
                刪除
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onPrint({ mode: 'selected' })"
              >
                <FontAwesome icon="print" type="fas" class="w-4 h-4 mr-2" />
                列印
              </span>
              <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'csv', mode: 'selected' })"
              >
                <FontAwesome icon="file-csv" type="fas" class="w-4 h-4 mr-2" />
                匯出 CSV
              </span>
              <!-- <span
                class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                @click="grid.onExport({ type: 'html', mode: 'selected' })"
              >
                <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" /> 匯出
                HTML
              </span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div>
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
          <div class="dropdown w-1/2 sm:w-auto ml-2">
            <button
              class="dropdown-toggle button w-full sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            >
              <FontAwesome icon="file-export" class="w-4 h-4 mr-2" /> 匯出
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-box">
              <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'csv' })"
                >
                  <FontAwesome icon="file-csv" class="w-4 h-4 mr-2" /> CSV
                </a>
                <!-- <a
                  href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                  @click="grid.onExport({ type: 'html' })"
                >
                  <FontAwesome icon="file" type="fas" class="w-4 h-4 mr-2" />
                  HTML
                </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2">
        <Grid
          ref="grid"
          v-bind="gridOptions"
          @addNewRow="
            (row, callback) => {
              (row.Selectable = false), (row.Required = false);
              row.Ordinal = 0;
              callback();
            }
          "
          @edit="onGridEdit"
          @removeSelectedRows="onGridRemoveSelectedRows"
        >
          <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              :title-width="60"
              title-align="right"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
              <template #column-category-id="{ data }">
                <SelectBox
                  :transfer="true"
                  v-model="data.CategoryId"
                  v-bind="categoryIdSelectOptions"
                ></SelectBox>
              </template>

              <template #column-multiple="{ data }">
                <vxe-switch
                  v-model="data.Multiple"
                  :disabled="!data.Selectable"
                  open-label="是"
                  close-label="否"
                ></vxe-switch>
              </template>
            </vxe-form>

            <fieldset v-if="row.Id" class="border rounded m-2 px-2 pt-2">
              <div class="flex items-center mx-1 mb-2">
                選項
                <button class="ml-auto" @click="valueGrid.addNewRow">
                  <FontAwesome icon="plus" class="w-5 h-5 text-yellow-500" />
                </button>
              </div>
              <Grid
                ref="valueGrid"
                v-bind="valueGridOptions"
                @addNewRow="
                  (row, callback) => {
                    row.AttributeId = grid.editingRow.Id;
                    callback();
                  }
                "
              />
            </fieldset>
          </template>
          <template #modal-footer>
            <vxe-button
              type="submit"
              status="primary"
              content="確認"
              @click="
                $refs.form.validate(errMap => {
                  if (errMap === undefined) $refs.form.dispatchEvent('submit');
                })
              "
            ></vxe-button>
            <vxe-button
              type="reset"
              content="重置"
              @click="$refs.form.dispatchEvent('reset')"
            ></vxe-button>
          </template>
        </Grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
</template>

<style scoped>
.swiper-container {
  padding-top: 5px;
  padding-bottom: 30px;
}
</style>

<script lang="ts">
import CloudFun, {
  Condition,
  defineComponent,
  Operator,
  ref
} from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;

    const grid = ref<any>({});

    var printColumns = [
      { field: "Code" },
      { field: "Name" },
      { field: "Width" },
      { field: "Height" },
      { field: "Remark" }
    ];

    const gridOptions: GridOptions = {
      title: "屬性",
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "廣告清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "廣告清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Code",
          title: "代碼",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Category.Name",
          title: "類別",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Selectable",
          title: "選擇型",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          resizable: false,
          width: 100,
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        {
          field: "Required",
          title: "必填",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          resizable: false,
          width: 100,
          align: "center",
          cellRender: {
            name: "$switch",
            props: { openLabel: "是", closeLabel: "否", disabled: true }
          }
        },
        { field: "Remark", title: "備註", resizable: false },
        {
          field: "Ordinal",
          title: "排序",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          resizable: false,
          align: "center",
          width: 100
        }
      ],
      promises: {
        query: model
          ? params => model.dispatch("attribute/query", params)
          : undefined,
        queryAll: model ? () => model.dispatch("attribute/query") : undefined,
        save: model
          ? params => model.dispatch("attribute/save", params)
          : undefined
      },
      modalConfig: { width: 800, showFooter: true }
    };
    const formOptions = {
      items: [
        {
          field: "Code",
          title: "代碼",
          span: 8,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Name",
          title: "名稱",
          span: 16,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        },
        {
          field: "Selectable",
          title: "選擇型",
          span: 8,
          itemRender: {
            name: "$select",
            options: [
              { label: "是", value: true },
              { label: "否", value: false }
            ]
          }
        },
        {
          field: "CategoryId",
          title: "類別",
          span: 16,
          slots: { default: "column-category-id" }
        },
        {
          field: "Multiple",
          title: "多選",
          span: 8,
          slots: { default: "column-multiple" }
        },
        {
          field: "Required",
          title: "必填",
          span: 8,
          itemRender: {
            name: "$select",
            options: [
              { label: "是", value: true },
              { label: "否", value: false }
            ]
          }
        },

        {
          field: "Ordinal",
          title: "排序",
          span: 8,
          itemRender: {
            name: "$input",
            props: {
              type: "number",
              placeholder: "請輸入文字",
              clearable: true
            }
          }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true }
          }
        }
      ],
      rules: {
        Code: [{ type: "string", max: 32, required: true }],
        Name: [{ type: "string", max: 64, required: true }],
        Remark: [{ type: "string", max: 512 }]
      }
    };

    const valueGrid = ref({});

    const categoryIdSelectOptions: SelectBoxOptions = {
      showSearch: true,
      rowId: "Id",
      placeholder: "選擇分類",
      textField: "Name",
      valueField: "Id",
      formatText: async (row: any) => {
        let entity = await model!.dispatch("category/find", row.Id); // eslint-disable-line
        let text = entity.Name;
        while (entity.Parent) {
          entity = entity.Parent;
          text = `${entity.Name}>${text}`;
        }
        return text;
      },
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: true
        }
      ],
      promises: {
        find: value => model!.dispatch("category/find", value), // eslint-disable-line
        query: params => {
          if (params.condition)
            params.condition.and("ParentId", Operator.IsNull);
          else params.condition = new Condition("ParentId", Operator.IsNull);

          return model!.dispatch("category/query", params); // eslint-disable-line
        }
      },
      treeConfig: { parent: "ParentId", children: "Children" }
    };

    const valueGridOptions: GridOptions = {
      mode: "inline",
      multiselect: false,
      columns: [
        {
          field: "TextValue",
          title: "文字(顯示或查詢用)",
          showOverflow: true,
          sortable: true,
          resizable: false,
          editRender: { name: "$input", immediate: true }
        },
        {
          field: "DecimalValue",
          title: "數值(比較或排序用)",
          showOverflow: true,
          sortable: true,
          resizable: false,
          editRender: {
            name: "$input",
            immediate: true,
            props: { type: "number" }
          }
        },
        {
          field: "Value",
          title: "值",
          showOverflow: true,
          sortable: true,
          resizable: false,
          editRender: { name: "$input", immediate: true }
        }
      ],
      promises: {
        query: model
          ? params => {
              params.condition = new Condition(
                "AttributeId",
                Operator.Equal,
                grid.value.editingRow?.Id || ""
              ).and(params.condition!);
              return model.dispatch("attributeValue/query", params);
            }
          : undefined, // eslint-disable-line
        queryAll: model
          ? () => model.dispatch("attributeValue/query")
          : undefined,
        save: model
          ? params => model.dispatch("attributeValue/save", params)
          : undefined
      },
      modalConfig: { width: 600, showFooter: true }
    };

    return {
      grid,
      gridOptions,
      formOptions,
      valueGrid,
      valueGridOptions,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      categoryIdSelectOptions
    };
  },
  methods: {
    onGridRemoveSelectedRows(rows: any[], callback: any) {
      cash("#batch-dropdown").dropdown("hide");
      callback();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id
        ? await this.$model.dispatch("attribute/find", row.Id)
        : undefined;

      if (entity) Object.assign(row, entity);
      callback();
    }
  }
});
</script>
